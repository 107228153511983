import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Main.scss';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ReactComponent as DeveloperIcon } from "../assets/developer.svg"; // SVG as a component
import Typewriter from "typewriter-effect";
import Tilt from "react-parallax-tilt";
import setup from '../assets/data/setup.json';

function Main() {
  return (
    <div className="w-full sm:flex flex-col items-center justify-center">
      <div className="about-section !justify-between">
        <div className="image-wrapper hidden">
          <img 
            className=""
            src="/avi.jpeg" 
            alt="Avatar" 
          />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/hideeLaalaa" title="Github repo" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/idrisade/" title="Linkedin profile" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href={setup.resume_url} title="Download Resume" target="_blank" rel="noopener noreferrer" ><GetAppIcon /></a>
          </div>
          <h1>Idris Adesina</h1>
          <p>
            <Typewriter
              options={{
                strings: ["Developer", "Software Engineer","Frontend Developer","Backend Developer","Computer Engineer"],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
              }}
            />
          </p>

          <div className="mobile_social_icons">
            <a href="https://github.com/hideeLaalaa" title="Github repo" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/idrisade/" title="Linkedin profile" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
            <a href={setup.resume_url} title="Download Resume" target="_blank" rel="noopener noreferrer" ><GetAppIcon /></a>
          </div>

        </div>
        <div className="">
          <Tilt>
            <DeveloperIcon className="w-full h-auto dark:text-fuchsia-800 text-gray-400" /> {/* Using SVG as a component */}
          </Tilt>
        </div>
      </div>
    </div>
  );
}

export default Main;
