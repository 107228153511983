import React from "react";
import '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import setup from '../assets/data/setup.json';
import '../assets/styles/Timeline.scss';
import TitleBlock from "./TitleBlock";

const experiences = setup.experiences;


const Timeline = () => {
  return (
    <div id="experience">
      <div className="items-container">
        <TitleBlock title="Work Experience" />
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'white', color: 'rgb(199, 230, 246)' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
              date={experience.date}
              iconStyle={{ background: '#3b82f6', color: 'rgb(199, 230, 246)' }}
              icon={<FontAwesomeIcon icon={faBriefcase} />}
            >
              <h3 className="vertical-timeline-element-title font-bold">{experience.title}</h3>
              <div className="flex flex-wrap justify-between align-middle items-center">
                <h4 className="font-semibold flex gap-2 align-middle items-center vertical-timeline-element-subtitle">
                  {experience.subtitle}
                  {experience.link && (
                    <a
                      href={experience.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 flex items-center"
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} className="!text-inherit h-3.5" />
                    </a>
                  )}
                </h4>
                <h5 className="text-gray-600 text-sm italic">{experience.country}</h5> {/* Added country display */}
              </div>
              <p className="!m-0">
                {experience.description}
              </p>
              <ul className="mt-2 pl-5 text-base text-gray-600 list-disc">
                {experience.bulletPoints.map((point, idx) => (
                  <li key={idx} className="text-gray-700">{point}</li>
                ))}
              </ul>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
}



export default Timeline;
