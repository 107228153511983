import React from "react";
import TitleBlock from "./TitleBlock";
import setup from '../assets/data/setup.json';

const educationDetails = setup.education;

function Education() {
  return (
    <div className="items-container" id="education">
      <TitleBlock title="Education" />
      <div className="space-y-6">
        {educationDetails.map((education, index) => (
          <div
            key={index}
            className="dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl shadow-gray-400 dark:shadow-inherit transition duration-300"
          >
            <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-1">
              {education.institution}
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300 mb-1">{education.degree} {<span className="text-sm italic">({education.grade})</span>}</p>
            <p className="text-gray-600 dark:text-gray-400 italic mb-2">{education.field}</p>
            <p className="text-sm text-gray-500 dark:text-gray-500">{education.duration}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Education;
