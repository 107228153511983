import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Import the WhatsApp icon
import '../assets/styles/Footer.scss';

function Footer() {
  return (
    <footer>
      <div>
        <a href="https://github.com/hideeLaalaa" target="_blank" rel="noreferrer">
          <GitHubIcon />
        </a>
        <a href="https://www.linkedin.com/in/idrisade/" target="_blank" rel="noreferrer">
          <LinkedInIcon />
        </a>
        <a href="https://wa.me/2348107909407" target="_blank" rel="noreferrer"> {/* WhatsApp link */}
          <WhatsAppIcon />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
