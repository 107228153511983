import React from "react";
// import setup from '../assets/data/setup.json';
import cvm from '../assets/images/cvm-1.png';
import chatgptGif from '../assets/images/ChatGPT-custom2.gif';
import '../assets/styles/Project.scss';
import TitleBlock from "./TitleBlock";

const projects = [
  {
    "title": "Core Vitals Monitor",
    "link": "https://wordpress.org/plugins/core-vitals-monitor/",
    "image": cvm,
    "description": "Developed a wordpress plugin to monitor and assess the speed and security status of a webpage. Additionally, it alerts users whenever the performance score falls below an acceptable threshold."
  },
  {
    "title": "ChatGPT with folder system",
    "link": "https://Demo2024:123456789@glixquoter.com/chatbot?demo",
    "image": chatgptGif,
    "description": "A folder system integrated to organize chats with GPT."
  }
];

function Project() {
  return (
    <div className="projects-container" id="projects">
      <TitleBlock title="Projects" />
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div className="project" key={index}>
            <a href={project.link} target="_blank" rel="noreferrer">
              <img src={project.image} className="zoom" alt="thumbnail" width="100%" />
            </a>
            <a href={project.link} target="_blank" rel="noreferrer">
              <h2 className="my-1">
                {project.title}
              </h2>
            </a>
            <p className="text-gray-300 text-base">
              {project.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Project;
