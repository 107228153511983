import React, { useRef, useState } from 'react';
import '../assets/styles/Contact.scss';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import TitleBlock from './TitleBlock';
import setup from '../assets/data/setup.json';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Contact() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    setNameError(name === '');
    setEmailError(email === '');
    setMessageError(message === '');

    if (name !== '' && email !== '' && message !== '') {
      setIsLoading(true);
      const templateParams = {
        from_name: name,
        email: email,
        message: message,
      };

      try {
        await emailjs.send(setup.emailJS_serviceID, setup.emailJS_templateID, templateParams, setup.emailJS_publicKey);
        setSnackbarMessage('Email sent successfully!');
        setSnackbarSeverity('success');
        setName('');
        setEmail('');
        setMessage('');
      } catch (error) {
        setSnackbarMessage('Failed to send email. Please try again later.');
        setSnackbarSeverity('error');
      } finally {
        setIsLoading(false);
        setOpenSnackbar(true); // Show the Snackbar
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div id="contact" className="">
      <div className="items-container">
        <div className="contact_wrapper">
          <TitleBlock title="Contact" />
          <p>Got a project waiting to be realized? Let's collaborate and make it happen!</p>

          <form ref={form} onSubmit={sendEmail} className="contact-form space-y-6 mt-4">
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
              <div>
                <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2" htmlFor="name">
                  Your Name
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${nameError ? 'border-red-500' : ''}`}
                  id="name"
                  type="text"
                  placeholder="What's your name?"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && <p className="text-red-500 text-xs italic">Please enter your name</p>}
              </div>

              <div>
                <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2" htmlFor="email">
                  Email / Phone
                </label>
                <input
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${emailError ? 'border-red-500' : ''}`}
                  id="email"
                  type="text"
                  placeholder="How can I reach you?"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && <p className="text-red-500 text-xs italic">Please enter your email or phone number</p>}
              </div>
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${messageError ? 'border-red-500' : ''}`}
                id="message"
                rows={4}
                placeholder="Send me any inquiries or questions"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {messageError && <p className="text-red-500 text-xs italic">Please enter the message</p>}
            </div>
            <div className='flex justify-between items-center gap-4 flex-wrap-reverse '>
              {/* Add Calendly link for booking a meeting */}
              <div className="sm:w-10/12">
                <p>If you'd prefer to discuss in more detail, feel free to book a 30-minute consultation:</p>
                <a
                  href="https://calendly.com/detunj/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:scale-100 hover:underline flex items-center"
                >
                  <CalendarTodayIcon className="mr-2" /> Book a meeting with me
                </a>
              </div>

              <button
                type="submit"
                className={`w-40 h-fit border !bg-blue-500 !text-white hover:opacity-70 font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline ${isLoading ? 'cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>

          <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default Contact;
