import React from "react";
import '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faPhp, faDocker, faAndroid } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';
import TitleBlock from "./TitleBlock";

const mernStack = [
  "ReactJS",
  "NodeJS",
  "TypeScript",
  "JavaScript",
  "NextJS",
  "MongoDB",
  "MySQL",
];

const lampStack = [
  "PHP",
  "MySQL",
  "MySQL",
  "Apache",
  "Linux",
];

const deploymentStack = [
  "Git",
  "GitHub Actions",
  "AWS",
  "Linux",
];

const mobileStack = [
  "Java",
  "Android Studio",
  "React Native",
];

function Expertise() {
  return (
    <div className="" id="technologies">
      <div className="skills-container">
        <TitleBlock title="Technologies" />
        <div className="skills-grid">

          {/* MERN Stack */}
          <div className="skill">
            <FontAwesomeIcon icon={faReact} size="3x" />
            <h3>MERN Stack Development</h3>
            <p>I have built a diverse array of web applications from scratch using modern technologies such as ReactJS, NextJS, and NodeJS. Experiences include building CRM and ERP applications.</p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {mernStack.map((label, index) => (
                <Chip key={index} className='chip' label={label} />
              ))}
            </div>
          </div>

          {/* LAMP Stack */}
          <div className="skill">
            <FontAwesomeIcon icon={faPhp} size="3x" />
            <h3>LAMP Stack Development</h3>
            <p>Experienced in developing robust backend systems using PHP, MySQL, Apache, and Linux, focusing on scalability and performance optimization.</p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {lampStack.map((label, index) => (
                <Chip key={index} className='chip' label={label} />
              ))}
            </div>
          </div>

          {/* Deployment & Automation */}
          <div className="skill">
            <FontAwesomeIcon icon={faDocker} size="3x" />
            <h3>Deployment & Automation</h3>
            <p>I enable clients to set up deployments, CI/CD pipelines, and automation processes to ensure a seamless and successful application launch.</p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {deploymentStack.map((label, index) => (
                <Chip key={index} className='chip' label={label} />
              ))}
            </div>
          </div>

          {/* Mobile Application */}
          <div className="skill">
            <FontAwesomeIcon icon={faAndroid} size="3x" />
            <h3>Mobile Application Development</h3>
            <p>I have some experience in building mobile applications using Java, Android Studio, and React Native.</p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {mobileStack.map((label, index) => (
                <Chip key={index} className='chip' label={label} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expertise;
