import React from 'react';

interface TitleBlockProps {
  title: string;
}

const TitleBlock: React.FC<TitleBlockProps> = ({ title }) => {
  return (
    <h1
      className="w-fit text-4xl font-bold mb-6 text-center text-white border-b-4 border-blue-500 inline-block animate-fade-in"
    >
      {title}
    </h1>
  );
};

export default TitleBlock;
